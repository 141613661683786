<template>
  <div>
    <PageHeader :title="'Demanda e Consumo'" :items="breadcrumb" />

    <!-- Filtro -->
    <b-card class="mb-3 mt-3">
      <b-container fluid class="ml-3 mt-2">
        <b-row>
          <!-- Ponto Medição -->
          <b-col md="4">
            <b-form-group label="Ponto Medição" label-for="pontoMedicao">
              <b-form-select
                id="pontoMedicao"
                v-model="filtroPontoMedicao"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Mês Referência -->
          <b-col md="4">
            <b-form-group label="Mês Referência" label-for="mesRef">
              <input
                type="text"
                class="form-control"
                v-model="filtroMesRef"
                v-mask="'##/####'"
              />
            </b-form-group>
          </b-col>

          <!-- Acumulado -->
          <b-col md="4">
            <b-form-group label="Acumulado" label-for="acumuladoSelect">
              <b-form-select
                id="acumuladoSelect"
                v-model="isAcumulado"
                :options="[
                  { value: true, text: 'Acumulado' },
                  { value: false, text: 'Não Acumulado' }
                ]"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Tabela de Demanda e Consumo -->
    <b-card style="margin: 15px 0">
      <b-container fluid>
        <b-row>
          <b-col>
            <h4
              class="titulo"
              style="background-color: #004f59; color: white; padding: 10px"
            >
              Demanda e Consumo
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              :items="dadosTabela"
              :fields="fields"
              :striped="true"
              responsive="sm"
            >
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <BarChart
              :consolidacao_consumo="consolidacao_consumo"
              :acumulado="isAcumulado"
            ></BarChart>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import { listAll } from './ccee.service';
import { fetchGrafico } from './ccee.service';
import BarChart from './BarChart.vue';

Vue.use(VueMask);

export default {
  name: 'DemandaConsumo',
  data() {
    return {
      breadcrumb: [
        {
          text: 'CCEE Service',
          href: '#'
        },
        {
          text: 'Demanda e Consumo',
          active: true
        }
      ],
      filtroPontoMedicao: '',
      filtroMesRef: '',
      isAcumulado: false,
      dadosTabela: [],
      consolidacao_consumo: null,
      fields: [
        { key: 'tipo', label: 'Tipo' },
        { key: 'ponta', label: 'Ponta' },
        { key: 'foraPonta', label: 'Fora de Ponta' },
        { key: 'total', label: 'Total' }
      ]
    };
  },
  watch: {
    isAcumulado(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          // Força o componente a se renderizar novamente
        });
      }
    }
  },
  methods: {
    buscarDados() {
      listAll().then((response) => {
        if (response.success) {
          this.processarDados(response.content);
        } else {
          console.error('Erro ao buscar dados: ', response.message);
        }
      });
      fetchGrafico().then((response) => {
        //if (response.success) {
        this.consolidacao_consumo = response.consolidacao_consumo;
        //  } else {
        //   console.error('Erro ao buscar dados do gráfico: ', response.message);
        //  }
      });
    },
    processarDados(content) {
      const { consolidacao } = content;
      this.dadosTabela = [
        {
          tipo: 'Consumo',
          ponta: consolidacao.cpta.toFixed(2) + ' MWh',
          foraPonta: consolidacao.cfpta.toFixed(2) + ' MWh',
          total: consolidacao.soma.toFixed(2) + ' MWh'
        },
        {
          tipo: 'Demanda',
          ponta: consolidacao.dmrpta.toFixed(2) + ' kW',
          foraPonta: consolidacao.dmrfpta.toFixed(2) + ' kW'
        }
      ];
    }
  },
  components: {
    PageHeader,
    BarChart
  },
  mounted() {
    this.buscarDados();
  }
};
</script>

<style>
.filtros {
  display: flex;
}

.form-group {
  flex-grow: 1;
  padding: 15px;
}

.titulo {
  text-align: center;
}
</style>
