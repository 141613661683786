<template>
  <div>
    <highcharts :options="chartOptions" ref="chartRef"></highcharts>
  </div>
</template>

<script>
import Vue from 'vue';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';

Vue.use(Highcharts);

export default {
  components: {
    highcharts: Chart
  },
  props: ['consolidacao_consumo', 'acumulado'],
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Consumo por Dia'
        },
        xAxis: {
          categories: [], // Inicializado como array vazio, será preenchido no hook created
          labels: {
            rotation: -45,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Consumo (kWh)'
          },
          labels: {
            formatter: function () {
              return this.value;
            }
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: 'gray'
            },
            format: '{total}'
          }
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          }
        },
        series: [
          {
            name: 'Realizado - Ponta',
            data: [], // Inicializado como array vazio, será preenchido no hook created
            color: '#FFCD00'
          },
          {
            name: 'Realizado - Fora de Ponta',
            data: [], // Inicializado como array vazio, será preenchido no hook created
            color: '#004F59'
          },
          {
            name: 'Simulado - Ponta',
            data: [], // Inicializado como array vazio, será preenchido no hook created
            color: '#4CB273'
          },
          {
            name: 'Simulado - Fora de Ponta',
            data: [], // Inicializado como array vazio, será preenchido no hook created
            color: '#f39c12'
          }
        ],
        credits: {
          enabled: false
        }
      }
    };
  },
  watch: {
    acumulado: {
      immediate: true,
      handler() {
        this.updateChart();
      }
    },
    consolidacao_consumo: {
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      if (!this.consolidacao_consumo) return;

      // Datas realizadas e simuladas separadas
      const datasRealizadas =
        this.consolidacao_consumo.valoresDiariosRealizados.datas;
      const datasSimuladas =
        this.consolidacao_consumo.valoresDiariosSimulados.datas;

      const newChartOptions = { ...this.chartOptions };
      newChartOptions.xAxis.categories = [
        ...datasRealizadas,
        ...datasSimuladas
      ];

      if (this.acumulado) {
        this.chartOptions.series[0].data = [
          ...this.consolidacao_consumo.valoresAcumuladosRealizados.ponta,
          ...Array(datasSimuladas.length).fill(null)
        ];
        this.chartOptions.series[1].data = [
          ...this.consolidacao_consumo.valoresAcumuladosRealizados.fora_ponta,
          ...Array(datasSimuladas.length).fill(null)
        ];
        this.chartOptions.series[2].data = [
          ...Array(datasRealizadas.length).fill(null),
          ...this.consolidacao_consumo.valoresAcumuladosSimulados.ponta
        ];
        this.chartOptions.series[3].data = [
          ...Array(datasRealizadas.length).fill(null),
          ...this.consolidacao_consumo.valoresAcumuladosSimulados.fora_ponta
        ];
      } else {
        this.chartOptions.series[0].data = [
          ...this.consolidacao_consumo.valoresDiariosRealizados.ponta,
          ...Array(datasSimuladas.length).fill(null)
        ];
        this.chartOptions.series[1].data = [
          ...this.consolidacao_consumo.valoresDiariosRealizados.fora_ponta,
          ...Array(datasSimuladas.length).fill(null)
        ];
        this.chartOptions.series[2].data = [
          ...Array(datasRealizadas.length).fill(null),
          ...this.consolidacao_consumo.valoresDiariosSimulados.ponta
        ];
        this.chartOptions.series[3].data = [
          ...Array(datasRealizadas.length).fill(null),
          ...this.consolidacao_consumo.valoresDiariosSimulados.fora_ponta
        ];
      }
    }
  },
  created() {
    this.updateChart();
  }
};
</script>
